<template>
  <v-card class="mx-auto px-4 pt-3 pb-16 mb-16" max-width="800" color="transparent" flat tile>
    <!-- Contact - Intro -->
    <section>
      <SectionTitle :text="$t('contact_us_title')" marginlesstop />
      <ContentParsed value="contact_us_intro" />
      <v-form ref="form" class="pt-2">
        <v-textarea
          v-model="textareaModel"
          :label="$t('contact_us_textarea')"
          clear-icon="mdi-close-circle"
          :rules="[rules.length(contactMessageMinLength)]"
          autofocus
          filled
          auto-grow
          clearable
          counter
        ></v-textarea>
        <br />
        <v-hover v-slot="{ hover }">
          <v-btn
            @click="sendMail()"
            :disabled="!isMessageValid"
            :color="hover ? 'primary lighten-1' : 'primary'"
            :depressed="!hover"
            large
            rounded
          >
            <v-icon left>mdi-email</v-icon>
            {{ $t("contact_us_button") }}
          </v-btn>
        </v-hover>
      </v-form>
    </section>

    <!-- Contact - Business -->
    <section>
      <SectionTitle :text="$t('contact_business_title')" />
      <ContentParsed value="contact_business_intro" />
    </section>
  </v-card>
</template>
<script>
import MixinView from "@/mixins/MixinView.js";
import database from "@/assets/data/database.json";

export default {
  name: "Contact",
  mixins: [MixinView],
  data: () => ({
    textareaModel: "",
    contactMessageMinLength: database["contactMessageMinLength"],
    rules: {
      length: function(len) {
        return function(val) {
          if (val === null) {
            return true;
          } else if (val.length >= len || val.length === 0) {
            return true;
          } else {
            return database["contactMessageMinLengthWarningPre"]+len+database["contactMessageMinLengthWarningPost"];
          }
        };
      }
    }
  }),
  methods: {
    sendMail() {
      let addr = database["contactMail"];
      let msg = this.textareaModel;

      window.location.href =
        "mailto:" + addr + "?subject=Message&body=" + encodeURI(msg);
    }
  },
  computed: {
    isMessageValid() {
      return (
        this.textareaModel && this.textareaModel.length >= this.contactMessageMinLength
      );
    }
  }
};
</script>
