import "@mdi/font/css/materialdesignicons.css";
// import 'material-design-icons-iconfont/dist/material-design-icons.css' //md
// import 'font-awesome/css/font-awesome.min.css' Font Awesome 4 //fa4
// import '@fortawesome/fontawesome-free/css/all.css' Font Awesome 5 //fa
import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import IconBGG from '@/components/specific/IconBGG.vue'
import IconGF from '@/components/specific/IconGF.vue'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#E08F17", //ch orange
        secondary: "#250904", //ch brown
        accent: "#ffcc80", //ch orange 100%
        error: colors.red, //red 500
        info: colors.blue, //blue 500
        success: colors.green, //green 500
        warning: colors.amber, //amber 500
        hut1: "#e08f17",
        hut2: "#ed741a",
        hut3: colors.deepOrange,
        ct1: "#e9423a",
        ct2: "#f9e300",
        ct3: "#57b35f",
        ct4: "#32b5e9",
        "t7s-blue-green": "#00AF98",
        "facebook": "#1877f2",
        "gamefound": "#1baa64",
        "ct-blue": "#4B98A6",
        "dis-blue": "#293758",
        "ludusum-primary": colors.purple
      },
      dark: {
        primary: "#E08F17", //ch orange
        secondary: "#250904", //ch brown
        accent: "#ffcc80", //ch orange 100%
        error: colors.red.lighten3, //red 200
        info: colors.blue.lighten3, //blue 200
        success: colors.green.lighten3, //green 200
        warning: colors.amber.lighten3, //amber 200
        hut1: "#e08f17",
        hut2: "#ed741a",
        hut3: colors.deepOrange, //colors.deepOrange.lighten3
        ct1: "#e9423a",
        ct2: "#f9e300",
        ct3: "#57b35f",
        ct4: "#32b5e9",
        "t7s-blue-green": "#00AF98",
        "facebook": "#1877f2",
        "gamefound": "#1baa64",
        "ct-blue": "#4B98A6",
        "dis-blue": "#293758",
        "ludusum-primary": colors.purple //colors.purple.lighten3
      }
    }
  },
  icons: {
    iconfont: "mdiSvg", // "mdi" || "mdiSvg" || "md" || "fa" || "fa4" || "faSvg"
    values: {
      bggIcon: { component: IconBGG},
      gfIcon: { component: IconGF},
    }
  }
});
