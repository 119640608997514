<template>
	<v-scroll-x-transition>
		<v-timeline-item v-show="item.star||switchModel" :icon="timelineIcon(item.star)" :color="timelineColor(item.star)"  fill-dot small>
			<div class="py-4">
				<v-row v-if="item.details">
					<v-expansion-panels tile flat>
						<v-expansion-panel color="primary">
							<v-expansion-panel-header class="pa-0 minHeightAuto">
								<span class="font-weight-bold" :class="item.star?'text-h6':'text-body-1'">{{ item.title }}</span>
								<template v-slot:actions>
									<v-icon color="primary" large>$expand</v-icon>
								</template>
							</v-expansion-panel-header>
							<v-expansion-panel-content class="timelineExpansionPanelContent">
								<ul>
									<li v-for="(text,i) in item.details" :key="i">{{ text }}</li>
								</ul>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-row>
				<v-row v-else>
					<span class="font-weight-bold" :class="item.star?'text-h6':'text-body-1'">{{ item.title }}</span>
				</v-row>
				<v-row class="pt-2">
					<span>{{ item.date }} - v{{ item.version }}</span>
				</v-row>
			</div>
		</v-timeline-item>
	</v-scroll-x-transition>
</template>

<script>

export default {
	name: "TimelineItem",
	props: {
		item: Object,
		switchModel: Boolean
	},
	methods: {
		timelineIcon(isStar){
			return isStar?'mdi-star':'mdi-circle-medium'
		},
		timelineColor(isStar){
			return isStar?'primary':'primary lighten-2'
		}
	}
};
</script>

<style scoped>
.minHeightAuto {
	min-height: auto !important;
}
</style>

<style>
.timelineExpansionPanelContent > .v-expansion-panel-content__wrap {
	padding: 8px 0px 8px 0px !important;
}
</style>