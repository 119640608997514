function isHTML(text) {
  if (text.startsWith("~")) {
    return true;
  }
  return false;
}

function getHTML(text,isMarginless) {
  //Titles
  if (text.startsWith("~")) {
    text = text.substring(1, text.length);

    // **bold text**
    text = text.replace(
      /\*\*([^*]+)\*\*/g,
      '<span class="font-weight-bold">$1</span>'
    );

    // *italic text*
    text = text.replace(
      /\*([^*]+)\*/g,
      '<span class="font-italic text--secondary">$1</span>'
    );

    // Colored Text - {text}(color)
    text = text.replace(/\{([^()]+)\}\(([^() ]+)\)/g, "<span style=\"color:$2\">$1</span>");

    if (isMarginless) {
      return "<span>" + text + "</span>";
    } else {
      return "<p>" + text + "</p>";
    }
  } else {
    return null;
  }
}

export { isHTML, getHTML };
