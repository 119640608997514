<template>
  <v-app>
    <AppBar />
    <NavigationDrawer />
    <Main />
    <Footer />
  </v-app>
</template>

<script>
import AppBar from "@/components/app/AppBar.vue";
import NavigationDrawer from "@/components/app/NavigationDrawer.vue";
import Main from "@/components/app/Main.vue";
import Footer from "@/components/app/Footer.vue";

export default {
  name: "App",
  components: {
    AppBar,
    NavigationDrawer,
    Main,
    Footer
  }
};
</script>

<style lang="sass">
@import '../node_modules/typeface-roboto/index.css'
</style>

<style>
/*@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";*/

a {
  outline: 0;
}

/*.alinea {
  text-indent: 20px;
}*/

.pointer {
  cursor: pointer;
}

/*.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.35s;
  transition-property: opacity;
  transition-timing-function: ease-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}*/
</style>
