<template>
  <div ref="el">
    <div v-if="marginlesstop">
      <h1 class="hidden-xs-only mb-4 text-h4 font-weight-bold">{{ text }}</h1>
      <h1 class="hidden-sm-and-up mb-2 text-h5 font-weight-bold">{{ text }}</h1>
    </div>
    <div v-else>
      <h1 class="hidden-xs-only mt-10 mb-4 text-h4 font-weight-bold">
        {{ text }}
      </h1>
      <h1 class="hidden-sm-and-up mt-8 mb-2 text-h5 font-weight-bold">
        {{ text }}
      </h1>
    </div>
  </div>
</template>
<script>
export default {
  name: "SectionTitle",
  props: {
    text: String,
    marginlesstop: Boolean
  }
};
</script>
