<template>
  <v-card class="mx-auto px-4 pt-3 pb-16 mb-16" max-width="800" color="transparent" flat tile>
    <section class="text-center mb-8">
      <h1 class="mb-4 text-h3 font-weight-bold">{{ $t('t7s_title') }}</h1>
      <ImageDialog src="assets/img_games/T7S box 3 - EN.webp" :title="$t('games_t7s_title')" :height="400" />
      <br />
      <span>{{ $t('t7s_intro_1') }}</span><br />
      <span>{{ $t('t7s_intro_2') }}</span>
    </section>

    <!-- T7S - Presentation -->
    <section>
      <ContentParsed value="t7s_presentation" />
    </section>

    <!-- T7S - Visuels -->
    <section>
      <!-- <SectionTitle :text="$t('t7s_visuel_title')" /> -->
      <ImageDialog src="assets/img_games/T7S game preview.webp" :title="$t('t7s_image_title')" :height="400" />
    </section>

    <!-- T7S - Project state -->
    <section>
      <SectionTitle :text="$t('t7s_timeline_title')" />
      <v-timeline dense>
        <v-timeline-item small fill-dot color="success" icon="mdi-check-bold" class="font-weight-bold green--text">
          {{$t('games_t7s_steps')[0]}}
        </v-timeline-item>
        <v-timeline-item small fill-dot color="success" icon="mdi-check-bold" class="font-weight-bold green--text">
          {{$t('games_t7s_steps')[1]}}
        </v-timeline-item>
        <v-timeline-item small fill-dot color="success" icon="mdi-check-bold" class="font-weight-bold green--text">
          {{$t('games_t7s_steps')[2]}}
        </v-timeline-item>
        <v-timeline-item small fill-dot color="hut1" icon="mdi-star-four-points" class="font-weight-bold orange--text">
          {{$t('games_t7s_steps')[3]}}
        </v-timeline-item>
        <v-timeline-item small fill-dot color="grey">
          {{$t('games_t7s_steps')[4]}}
        </v-timeline-item>
      </v-timeline>
    </section>

    <!-- T7S - Social -->
    <section>
      <SectionTitle :text="$t('t7s_social_title')" />
      <v-row no-gutters>
        <ButtonHover :title="$t('t7s_button_facebook')" color="facebook" icon="mdi-facebook" :link="t7sFacebook" />
        <ButtonHover :title="$t('t7s_button_gamefound')" color="gamefound" icon="mdi-web" :link="t7sGamefound" />
      </v-row>
    </section>
  </v-card>
</template>
<script>
  import MixinView from "@/mixins/MixinView.js";
  import database from "@/assets/data/database.json";

  export default {
    name: "The7Seas",
    mixins: [MixinView],
    beforeMount() {
      this.t7sFacebook=database["t7sFacebook"];
      this.t7sGamefound=database["t7sGamefound"];
    },
    components: {
    }
  };
</script>
