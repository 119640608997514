<template>
  <div>
    <!-- SM and up -->
    <div class="hidden-xs-only">
      <h2 class="mb-2 text-h5 font-weight-bold">
        {{ title }}<span class="font-weight-regular"> - {{ text }}</span>
      </h2>
    </div>
    <!-- XS -->
    <div class="hidden-sm-and-up mb-2 text-center">
      <span class="text-h5 font-weight-bold">{{ title }}</span
      ><br />
      <span class="text-h6 font-weight-regular">{{ text }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "GameCardTitle",
  props: {
    title: String,
    text: String
  }
};
</script>
