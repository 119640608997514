<template>
  <div>
      <div v-for="(text, index) of arrayContent" :key="index">
        <div v-if="isHTML(text)" v-html="getHTML(text,marginless)" />
        <span v-else-if="marginless">{{ text }}</span>
        <p v-else>{{ text }}</p>
      </div>  
  </div>
</template>
<script>
import { isHTML, getHTML } from "@/helpers/parser.js";

export default {
  name: "ContentParsed",
  props: {
    value: String,
    marginless: Boolean
  },
  methods: {
    isHTML,
    getHTML
  },
   computed: {
    arrayContent() {
      return this.$t(this.value)
    }
  },
};
</script>
