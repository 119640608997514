<template>
  <v-card class="mx-auto px-4 pt-3 pb-16 mb-16" max-width="800" color="transparent" flat tile>
    <!-- Team - Intro -->
    <section>
      <SectionTitle :text="$t('team_intro_title')" marginlesstop />
      <v-row>
        <TeamCard title="Thomas Biache" :description="$t('team_tbi')" icon="mdi-tie" />
        <TeamCard title="Matthieu Biache" :description="$t('team_mbi')" icon="mdi-cog" />
        <TeamCard title="Gilles Lemonnier" :description="$t('team_gle')" icon="mdi-star" />
      </v-row>
    </section>

    <section>
      <SectionTitle :text="$t('team_authors_illustrators_title')" />
      <v-row>
        <TeamCard title="Joseph La Marca" :description="$t('team_jlm')" icon="mdi-chat" />
        <TeamCard title="Yvan Tesi" :description="$t('team_yte')" icon="mdi-brush" />
        <TeamCard title="Jonathan Aucomte" :description="$t('team_jau')" icon="mdi-brush" />
        <TeamCard title="Mathilde Durand" :description="$t('team_mdur')" icon="mdi-brush" />
      </v-row>
    </section>

    <!-- Team - Past collaborators -->
    <!-- <section>
      <SectionTitle :text="$t('team_past_title')" />
      <v-row>
        <TeamCard title="Ana Tacale" :description="$t('team_ata')" icon="mdi-briefcase" />
        <TeamCard title="Camille Driutti" :description="$t('team_cdr')" icon="mdi-magnify" />
        <TeamCard title="Eric Szimjonka" :description="$t('team_esz')" icon="mdi-play" />
        <TeamCard title="Hubert Rinner" :description="$t('team_hri')" icon="mdi-translate" />
        <TeamCard title="Maud Duquenoy" :description="$t('team_mdu')" icon="mdi-translate" />
        <TeamCard title="Philippe Houberdon" :description="$t('team_phu')" icon="mdi-laptop" />
        <TeamCard title="Silvia Cabras" :description="$t('team_sca')" icon="mdi-translate" />
        <TeamCard title="Steffen Horn" :description="$t('team_sho')" icon="mdi-laptop" />
        <TeamCard title="Vanessa Coeurnelle" :description="$t('team_vco')" icon="mdi-translate" />
      </v-row>
    </section> -->

    <!-- Team - Join us -->
    <!-- <section>
      <SectionTitle :text="$t('team_joinsus_title')" />
      <ContentParsed value="team_joinus_intro" />
      <ButtonHover :title="$t('team_joinus_button')" path="/contact" color="primary" icon="mdi-email"/>
    </section> -->
  </v-card>
</template>
<script>
import MixinView from "@/mixins/MixinView.js";
import TeamCard from "@/components/specific/TeamCard.vue";

export default {
  name: "Team",
  mixins: [MixinView],
  components: {
    TeamCard
  },
  methods: {
    cardTextClasses(hover) {
      let text = "d-flex transition-fast-in-fast-out";
      text += hover ? " ml-1 white--text" : " grey--text text--darken-2";
      return text;
    }
  }
};
</script>
