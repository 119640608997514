<template>
  <v-dialog v-model="dialog" max-width="800" overlay-color="#180805">
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-card
          v-on="on"
          v-bind="attrs"
          :ripple="false"
          color="transparent"
          flat
          link
          :aria-label="title"
        >
          <v-expand-transition origin="center">
            <v-img v-if="hover"
              class="d-flex transition-fast-in-fast-out"
              :src="srcImage"
              :max-height="expandedHeight"
              contain
            />
            <v-img v-else
              class="d-flex transition-fast-in-fast-out"
              :src="srcImage"
              :max-height="height"
              contain
            />
          </v-expand-transition>
        </v-card>
      </v-hover>
    </template>
    <v-card @click="dialog = false" :ripple="false" flat link>
      <v-card-text class="d-flex transition-fast-in-fast-out pa-4">
        <v-img :src="srcImage" contain max-height="500"></v-img>
      </v-card-text>
      <!-- <v-divider></v-divider> -->
      <v-card-title v-if="title" class="px-4 pt-0">
        <v-row no-gutters justify="center">
          {{ title }}
        </v-row>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ImageDialog",
  data: () => ({
    dialog: false
  }),
  props: {
    src: String,
    title: String,
    height: Number
  },
  computed: {
    expandedHeight() {
      return 25+this.height;
    },
    srcImage() {
      return require("@/" + this.src);
    }
  }
};
</script>
