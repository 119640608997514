<template>
  <!-- marginless mode - No margin -->
  <div v-if="mode === 'marginless'">
    <div v-if="level === 1">
      <span class="hidden-xs-only text-h4 font-weight-bold">{{ text }}</span>
      <span class="hidden-sm-and-up text-h5 font-weight-bold">{{ text }}</span>
    </div>
    <div v-else-if="level === 2">
      <span class="hidden-xs-only text-h5 font-weight-bold">{{ text }}</span>
      <span class="hidden-sm-and-up text-h6 font-weight-bold">{{ text }}</span>
    </div>
    <div v-else-if="level === 3">
      <span class="hidden-xs-only text-h5 font-weight-medium">{{ text }}</span>
      <span class="hidden-sm-and-up text-h6 font-weight-medium">{{
        text
      }}</span>
    </div>
    <div v-else>
      <span class="hidden-xs-only text-h6 font-weight-medium">{{ text }}</span>
      <span class="hidden-sm-and-up text-h7 font-weight-medium">{{
        text
      }}</span>
    </div>
  </div>
  <!-- reduced mode - Reduced Top Margin -->
  <div
    v-else-if="mode === 'reduced'"
    ref="el"
    @click="goToElement"
    class="pointer"
  >
    <v-hover v-slot="{ hover }">
      <div
        :class="(hover ? 'ml-1 ' : '') + 'd-flex transition-fast-in-fast-out'"
      >
        <div v-if="level === 1">
          <h1 class="hidden-xs-only mb-2 text-h4 font-weight-bold">
            {{ text }}
          </h1>
          <h1 class="hidden-sm-and-up mt-10 mb-2 text-h5 font-weight-bold">
            {{ text }}
          </h1>
        </div>
        <div v-else-if="level === 2">
          <h2 class="hidden-xs-only mb-2 text-h5 font-weight-bold">
            {{ text }}
          </h2>
          <h2 class="hidden-sm-and-up mb-2 text-h6 font-weight-bold">
            {{ text }}
          </h2>
        </div>
        <div v-else-if="level === 3">
          <h3 class="hidden-xs-only mb-2 text-h5 font-weight-medium">
            {{ text }}
          </h3>
          <h3 class="hidden-sm-and-up mb-2 text-h6 font-weight-medium">
            {{ text }}
          </h3>
        </div>
        <div v-else>
          <h4 class="hidden-xs-only mb-2 text-h6 font-weight-medium">
            {{ text }}
          </h4>
          <h4 class="hidden-sm-and-up mb-2 text-h7 font-weight-medium">
            {{ text }}
          </h4>
        </div>
      </div>
    </v-hover>
  </div>
  <!-- normal mode - Margin -->
  <div v-else ref="el" @click="goToElement" class="pointer">
    <v-hover v-slot="{ hover }">
      <div
        :class="(hover ? 'ml-1 ' : '') + 'd-flex transition-fast-in-fast-out'"
      >
        <div v-if="level === 1">
          <h1 class="mt-10 hidden-xs-only mb-2 text-h4 font-weight-bold">
            {{ text }}
          </h1>
          <h1
            class="mt-10 hidden-sm-and-up mt-10 mb-2 text-h5 font-weight-bold"
          >
            {{ text }}
          </h1>
        </div>
        <div v-else-if="level === 2">
          <h2 class="mt-8 hidden-xs-only mb-2 text-h5 font-weight-bold">
            {{ text }}
          </h2>
          <h2 class="mt-8 hidden-sm-and-up mb-2 text-h6 font-weight-bold">
            {{ text }}
          </h2>
        </div>
        <div v-else-if="level === 3">
          <h3 class="mt-6 hidden-xs-only mb-2 text-h5 font-weight-medium">
            {{ text }}
          </h3>
          <h3 class="mt-6 hidden-sm-and-up mb-2 text-h6 font-weight-medium">
            {{ text }}
          </h3>
        </div>
        <div v-else>
          <h4 class="mt-4 hidden-xs-only mb-2 text-h6 font-weight-medium">
            {{ text }}
          </h4>
          <h4 class="mt-4 hidden-sm-and-up mb-2 text-h7 font-weight-medium">
            {{ text }}
          </h4>
        </div>
      </div>
    </v-hover>
  </div>
</template>
<script>
export default {
  name: "SectionSubtitle",
  props: {
    text: String,
    level: Number,
    mode: String
  },
  methods: {
    goToElement() {
      this.$vuetify.goTo(this.$refs.el, {
        duration: 300,
        offset: 16,
        easing: "easeInOutCubic"
      });
    }
  }
};
</script>
