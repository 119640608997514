<template>
  <v-card class="mx-auto px-4 pt-3 pb-16 mb-16" max-width="800" color="transparent" flat tile>
    <!-- Games - Upcoming -->
    <section>
      <SectionTitle :text="$t('games_upcoming_title')" marginlesstop />
      <v-card class="px-6 py-4 rounded-lg" style="border-top: 8px solid #00AF98">
        <GameCardTitle :title="$t('games_t7s_title')" :text="$t('games_t7s_titletext')" />
   
        <GameCard
          imgSrc="assets/img_games/T7S box 3 - EN.webp"
          :title="$t('games_t7s_title')"
          textValue="games_t7s_text"
          :chips="sevenseasChips"
          hiddenTitle
        />
        <!-- XS -->
        <v-timeline dense>
          <v-timeline-item small fill-dot color="success" icon="mdi-check-bold" class="font-weight-bold green--text">
            {{$t('games_t7s_steps')[0]}}
          </v-timeline-item>
          <v-timeline-item small fill-dot color="success" icon="mdi-check-bold" class="font-weight-bold green--text">
            {{$t('games_t7s_steps')[1]}}
          </v-timeline-item>
          <v-timeline-item small fill-dot color="success" icon="mdi-check-bold" class="font-weight-bold green--text">{{$t('games_t7s_steps')[2]}}</v-timeline-item>
          <v-timeline-item small fill-dot color="hut1" icon="mdi-star-four-points" class="font-weight-bold orange--text">{{$t('games_t7s_steps')[3]}}</v-timeline-item>
          <v-timeline-item small fill-dot color="grey">{{$t('games_t7s_steps')[4]}}</v-timeline-item>
        </v-timeline>
        <v-card-actions>
          <ButtonHover :title="$t('games_t7s_button')" path="/the7seas" color="t7s-blue-green" icon="mdi-compass" />
        </v-card-actions>
      </v-card>
    </section>

    <!-- Games - Edition -->
    <section>
      <SectionTitle :text="$t('games_own_title')" />
      <v-card class="px-6 py-4 rounded-lg" style="border-top: 8px solid #4B98A6">
        <GameCardTitle :title="$t('games_cardtrip_title')" :text="$t('games_cardtrip_titletext')" />
        <ContentParsed value="games_cardtrip_intro" />
        <GameCard
          imgSrc="assets/img_games/CT BRU Profile.webp"
          :title="$t('games_cardtrip_bxl_title')"
          textValue="games_cardtrip_bxl_text"
          :chips="cardtripChips"
        />
        <v-card-actions>
          <ButtonHover :title="$t('games_cardtrip_button')" :link="linkCardTrip" color="ct-blue" icon="mdi-cards" />
        </v-card-actions>
      </v-card>
    </section>

    <!-- Games - Distribution -->
    <section>
      <SectionTitle :text="$t('games_sale_title')" />
      <v-card class="px-6 py-4 rounded-lg" style="border-top: 8px solid #293758">
        <GameCardTitle :title="$t('games_discovery_title')" :text="$t('games_discovery_titletext')" />
        <ContentParsed value="games_discovery_intro" />
        <GameCard
          imgSrc="assets/img_games/DIS Préhistoire FR Profile.webp"
          :title="$t('games_discovery_prehistory_title')"
          textValue="games_discovery_prehistory_text"
          :chips="discoveryChips"
        />
        <br/>
        <GameCard
          imgSrc="assets/img_games/DIS Antiquité FR Profile.webp"
          :title="$t('games_discovery_antiquity_title')"
          textValue="games_discovery_antiquity_text"
          :chips="discoveryChips"
        />
        <v-card-actions>
          <ButtonHover :title="$t('games_discovery_button')" :link="linkDiscovery" color="dis-blue" icon="mdi-account-group"/>
        </v-card-actions>
      </v-card>
    </section>

    <!-- Games - Collection -->
    <section>
      <SectionTitle :text="$t('games_collection_title')" />
      <ContentParsed value="games_collection_intro" />
      <v-dialog v-model="isActiveDialog" max-width="1200" overlay-color="#180805">
        <template v-slot:activator="{ on, attrs }">
          <v-card v-bind="attrs" v-on="on" :ripple="false" color="transparent" flat link aria-label="Game collection">
            <!-- SM and up -->
            <v-lazy v-model="isActiveLazy" :options="{ threshold: 1 }" height="250" class="hidden-xs-only">
              <v-parallax
                src="@/assets/img_photos/Game Collection Mini.webp"
                height="250"
              ></v-parallax>
            </v-lazy>
            <!-- XS -->
            <v-lazy v-model="isActiveLazy" :options="{ threshold: 1 }" height="200" class="hidden-sm-and-up">
              <v-parallax
                src="@/assets/img_photos/Game Collection Mini.webp"
                height="200"
              ></v-parallax>
            </v-lazy>
          </v-card>
        </template>
        <v-card @click="isActiveDialog = false" :ripple="false" flat link>
          <v-card-text class="d-flex transition-fast-in-fast-out pa-8">
            <v-img src="@/assets/img_photos/Game Collection.webp" contain />
          </v-card-text>
        </v-card>
      </v-dialog>
      <br />
      <ContentParsed value="games_collection_main" />
      <ButtonHover :title="$t('games_collection_button')" path="/contact" color="primary" icon="mdi-email"/>
      <!-- <ButtonHover title="Learn more" :link="linkLudusun" color="ludusum-primary" /> -->
    </section>
  </v-card>
</template>
<script>
import MixinView from "@/mixins/MixinView.js";

import GameCard from "@/components/specific/GameCard.vue";
import GameCardTitle from "@/components/specific/GameCardTitle.vue";

import database from "@/assets/data/database.json";

export default {
  name: "Games",
  mixins: [MixinView],
  components: {
    GameCard,
    GameCardTitle
  },
  beforeMount() {
    this.linkCardTrip=database["linkCardTrip"];
    this.linkDiscovery=database["linkDiscovery"];
  },
  data: () => ({
    sevenseasChips: [
      { name: "games_t7s_chipname_1", color: "hut2" },
      { name: "games_t7s_chipname_2", color: "t7s-blue-green" }
    ],
    cardtripChips: [
      { name: "games_cardtrip_bxl_chipname_1", color: "hut1" },
      { name: "games_cardtrip_bxl_chipname_2", color: "ct-blue" }
    ],
    discoveryChips: [
      { name: "games_discovery_antiquity_chipname_1", color: "hut1" },
      { name: "games_discovery_antiquity_chipname_2", color: "dis-blue" }
    ],
    isActiveDialog: false,
    isActiveLazy: false
  })
};
</script>
