<template>
  <v-navigation-drawer
    v-model="isDrawerLocal"
    overlay-color="#180805"
    overlay-opacity="0.75"
    width="250"
    dark
    temporary
    app
  >
    <!-- Logo -->
    <template v-slot:prepend>
      <v-row justify="center" no-gutters>
        <v-col cols="9" class="">
          <router-link to="/">
            <v-card height="70" color="transparent" tile flat>
              <v-row @click="closeDrawer" class="fill-height" justify="center" align="center" no-gutters>
                <v-img
                src="@/assets/img_logos/logo.svg"
                max-height="44"
                min-height="44"
                min-width="170"
                max-width="170"
                contain
                ></v-img>
              </v-row>
            </v-card>
          </router-link>
        </v-col>
        <v-col cols="3" class="primary--text text-center text-h6 pt-7 px-1">
          <router-link to="/changelog">
            <span @click="closeDrawer">v{{ appChangelog[0].version }}</span>
          </router-link>
        </v-col>
      </v-row>
    </template>
    <v-divider></v-divider>
    <NavigationDrawerList></NavigationDrawerList>
    <v-divider></v-divider>
  </v-navigation-drawer>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import NavigationDrawerList from "@/components/app/NavigationDrawerList.vue";
import changelog from "@/assets/data/changelog.json"

export default {
  name: "NavigationDrawer",
  components: {
    NavigationDrawerList
  },
  beforeMount() {
    this.appChangelog=changelog["appChangelog"]
  },
  computed: {
    ...mapState("nav", ["isDrawer"]),
    isDrawerLocal: {
      get() {
        return this.isDrawer;
      },
      set(value) {
        this.setDrawer(value);
      }
    }
  },
  methods: {
    ...mapMutations("nav", ["setDrawer", "closeDrawer"])
  }
};
</script>

<style scoped>
a {
  outline: 0;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

</style>