<template>
  <v-main v-scroll="onScroll" v-resize="onResize">
    <v-container :class="{ 'px-0': isSm }" fluid>
      <v-scroll-x-transition mode="out-in" duration="300">
        <router-view></router-view>
      </v-scroll-x-transition>
      <MainFab :isDisplayed="isDisplayedFab" />
    </v-container>
    <MainFooter />
  </v-main>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import MainFooter from "@/components/app/MainFooter.vue";
import MainFab from "@/components/app/MainFab.vue";

export default {
  name: "Main",
  components: {
    MainFooter,
    MainFab
  },
  data: () => ({
    isDisplayedFab: false,
    isSm: false
  }),
  mounted() {
    this.updateFab();
    this.updateBadges();
  },
  beforeUpdate() {
    this.updateBadges();
  },
  computed: {
    ...mapGetters("nav", ["isVisitRemaining"])
  },
  methods: {
    ...mapMutations("nav", ["visitNav"]),
    updateBadges() {
      if (this.isVisitRemaining && this.$route.matched[0] ) {
        this.visitNav(this.$route.matched[0].meta.componentName);
      }
    },
    isOnTop() {
      return window.scrollY <= 32;
    },
    isSmallDisplay() {
      return window.innerWidth <= 600;
    },
    isOnBottom() {
      return (
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 32
      );
    },
    isPageTooSmall() {
      return document.body.offsetHeight - window.innerHeight < 196;
    },
    isFabToHide() {
      return (
        this.isOnTop() ||
        (this.isSmallDisplay() && this.isOnBottom()) ||
        this.isPageTooSmall()
      );
    },
    updateFab() {
      // Top hide
      if (this.isFabToHide()) {
        this.isDisplayedFab = false;
      } else {
        this.isDisplayedFab = true;
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    onScroll() {
      this.updateFab();
    },
    onResize() {
      this.updateFab();
      return (this.isSm = window.innerWidth <= 960);
    }
  }
};
</script>
