<template>
  <v-footer padless absolute dark>
    <v-row justify="center" no-gutters>
      <v-col class="text-center mt-2" cols="12">
        <v-btn
          v-for="item in navItems.filter(i => i.fieldName == 'nav_home')"
          :key="item.to"
          :to="item.to"
          active-class="primary--text"
          small text rounded
          :aria-label="item.fieldName"
        >
          <span>
            <v-badge :value="item.badge" offset-x="-2" offset-y="8" color="red" dot>
              <v-icon>{{ item.icon }}</v-icon>
            </v-badge>
          </span>
        </v-btn>
        <v-btn
          v-for="item in navItems.filter(i => i.fieldName != 'nav_home' && i.fieldName != 'nav_changelog' )"
          :key="item.to"
          :to="item.to"
          active-class="primary--text"
          small text rounded
          :aria-label="item.fieldName"
        >
          <span>
            <v-badge :value="item.badge" offset-x="-2" offset-y="2" color="red" dot>
              {{ $t(item.fieldName) }}
            </v-badge>
          </span>
        </v-btn>
      </v-col>
      <v-col class="text-center mt-1" cols="12">
        <v-btn :href="linkCHFacebook" icon><v-icon>mdi-facebook</v-icon></v-btn>
        <v-btn :href="linkCHInstagram" icon><v-icon>mdi-instagram</v-icon></v-btn>
        <v-btn :href="linkCHBoardgamegeek" icon><v-icon>$bggIcon</v-icon></v-btn>
        <v-btn :href="linkCHGamefound" icon><v-icon>$gfIcon</v-icon></v-btn>
      </v-col>
      <v-col class="text-center text-caption mt-1" cols="12">
        {{ $t("footer_trademark") }}
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import { mapState } from "vuex";
import database from "@/assets/data/database.json";

export default {
  name: "MainFooter",
  beforeMount() {
    this.linkCHFacebook=database["linkCHFacebook"];
    this.linkCHInstagram=database["linkCHInstagram"];
    this.linkCHBoardgamegeek=database["linkCHBoardgamegeek"];
    this.linkCHGamefound=database["linkCHGamefound"];
  },
  computed: {
    ...mapState("nav", ["navItems"])
  }
};
</script>






    