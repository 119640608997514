<template>
  <v-col cols="12" sm="6">
    <v-hover v-slot="{ hover }">
      <v-card :class="cardClass()" @click="isDisplayed = !isDisplayed" min-height="64" link>
        <v-card-text class="grey--text text--darken-2">
          <span :class="cardTitleClasses(hover)">
            <v-icon v-if="icon" :color="cardIconColor()" class="mr-3">{{ icon }}</v-icon>{{ title }}</span>
          <v-expand-transition>
            <span :class="cardTextClasses(hover)" v-if="isDisplayed" class="d-flex transition-fast-in-fast-out">{{ description }}</span>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-hover>
  </v-col>
</template>
<script>
// {{ description }}

export default {
  name: "TeamCard",
  props: {
    title: String,
    icon: String,
    description: String
  },
  data: () => ({
    isDisplayed: false
  }),
  methods: {
    cardClass() {
      return this.isDisplayed ? "teamcard-border-primary" : "teamcard-border-white";
    },
    cardTitleClasses(hover) {
      let text = "text-h6 d-flex transition-fast-in-fast-out";
      text += hover ? " ml-1" : "";
      text += this.isDisplayed ? " primary--text" : "";
      return text;
    },
    cardIconColor() {
      return this.isDisplayed ? "primary" : "grey darken-2";
    },
    cardTextClasses(hover) {
      let text = "d-flex transition-fast-in-fast-out";
      text += hover ? " ml-1" : "";
      return text;
    }
  }
};
</script>

<style scoped>

.teamcard-border-primary {
  border-left: 10px solid #E08F17;
}

.teamcard-border-white {
  border-left: 10px solid white;
}

</style>
