<template>
  <section>
    <!-- Future Events -->
    <v-list v-if="gameEvents.filter(hasNotPassed).length>0">
      <SectionTitle :text="$t('home_events_future_title')" />
      <EventItem v-for="(item,i) in gameEvents.filter(hasNotPassed).slice(0,3)" :key="i" :title="$t('home_events')[i][0]" :date="item[0]" :link="item[1]" :imageSrc="item[2]"/>
    </v-list>
    <!-- Past Events -->
    <v-list>
      <SectionTitle :text="$t('home_events_past_title')" />
      <EventItem v-for="(item,i) in gameEvents.filter(hasPassed).slice(0,3)" :key="i" :title="$t('home_events')[i+hasPassedOffset][0]" :date="item[0]" :link="item[1]" :imageSrc="item[2]"/>
    </v-list>
  </section>
</template>
<script>
  import MixinView from "@/mixins/MixinView.js";
  import EventItem from "@/components/specific/EventItem.vue";
  import database from "@/assets/data/database.json";

  export default {
    name: "EventsSection",
    mixins: [MixinView],
    beforeMount() {
      this.gameEvents=database["gameEvents"];
    },
    components: {
      EventItem
    },
    methods: {
      hasPassed(item) {
        const dateNowRaw=new Date();
        const dateNow=new Date(dateNowRaw.getFullYear(), dateNowRaw.getMonth(), dateNowRaw.getDate()).valueOf();
        const dateEvent=new Date(item[0]).valueOf();

        if(this.reverse){
          return !(dateNow>dateEvent);
        } else {
          return dateNow>dateEvent;
        }    
      },
      hasNotPassed(item) {
        return !this.hasPassed(item);
      }
    },
    computed: {
      hasPassedOffset(){
        return this.gameEvents.length-this.gameEvents.filter(this.hasPassed).length;
      }
    }
  };
</script>
