<template>
  <v-hover v-slot="{ hover }">
    <v-fab-transition origin="center center">
      <v-btn
        v-show="isDisplayed"
        :color="hover ? 'primary lighten-1' : 'primary'"
        @click="scrollToTop"
        v-resize="onResize"
        :small="isXs"
        class="white--text mb-8"
        aria-label="MainFab"
        fixed
        fab
        right
        bottom
      >
        <v-icon v-show="isXs" :class="{ 'pb-2': hover }"
          >mdi-arrow-up-thick</v-icon
        >
        <v-icon v-show="!isXs" :class="{ 'pb-2': hover }"
          >mdi-arrow-up-thick mdi-36px</v-icon
        >
      </v-btn>
    </v-fab-transition>
  </v-hover>
</template>
<script>
export default {
  name: "MainFab",
  props: {
    isDisplayed: Boolean
  },
  data: () => ({
    isXs: false
  }),
  methods: {
    scrollToTop() {
      if (window.scrollY != 0) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    onResize() {
      return (this.isXs = window.innerWidth <= 600);
    }
  }
};
</script>
