import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import nav from "@/assets/data/nav.json";
import i18n from "@/i18n";

function getNavRoutes() {
  let items = [];
  let navInfo = nav["navInfo"];
  let index = 0;

  navInfo.forEach(item => {
    items.push({
      path: item.path,
      name: item.componentName,
      component: () => import("@/views/" + item.componentName),
      meta: { 
        title: item.componentName,
        fieldName: item.fieldName,
        componentName: item.componentName
      },
      props: { id: index }
    });
    index++;
  });

  return items;
}

const routes = [
  // { path: "/", component: Home, meta: { title: "Home" } },
  ...getNavRoutes(),
  { path: "/*", redirect: "/" },
  { path: "*", component: Home, props: { isFrom404: true } }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

//Vue router
Vue.use(VueRouter);

router.beforeEach((to, from, next) => {
  //Updating title
  let title = "";

  if (to.path != "/") {
    title = i18n.t(to.meta.fieldName) + " - Critical Hut";
  } else {
    title = "Critical Hut";
  }
  document.title = title;
  next();
});

router.afterEach(() => {
  //Scrolling back to top
  if (window.scrollY != 0) {
    setTimeout(function() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 350);
  }
});

export default router;
