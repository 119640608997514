<template>
  <v-card class="mx-auto px-4 pt-3 pb-16 mb-16" max-width="800" color="transparent" flat tile>
    <!-- Legal - Intro -->
    <section>
      <SectionTitle text="Legal information" marginlesstop />
      <v-alert border="left" icon="mdi-alert" type="warning" color="primary" dismissible dense>{{$t('info_onlyenglish')}}</v-alert>
      <ContentParsed value="legal_info_intro" />
    </section>

    <!-- Legal - Terms -->
    <section>
      <SectionTitle text="Terms of service" />
      <ContentParsed value="legal_terms_intro_0" />
      <!-- Legal - Terms - General definitions -->
      <SectionSubtitle text="General definitions" :level="3" />
      <ContentParsed value="legal_terms_intro_1" />
      <!-- Legal - Terms - Definitions relating to natural and legal persons -->
      <SectionSubtitle
        text="Definitions relating to natural and legal persons"
        :level="3"
      />
      <ContentParsed value="legal_terms_intro_2" />
      <!-- Legal - Terms - Definitions relating to natural and legal persons -->
      <SectionSubtitle
        text="Definitions relating to natural and legal persons"
        :level="3"
      />
      <ContentParsed value="legal_terms_intro_3" />
      <v-expansion-panels class="rounded-lg mt-6" hover multiple>
        <!-- Legal - Terms - General Terms -->
        <v-expansion-panel class="rounded-tl-lg rounded-tr-lg">
          <v-expansion-panel-header
            expand-icon="mdi-chevron-down mdi-36px"
            ripple
          >
            <SectionSubtitle
              text="General Terms"
              :level="2"
              mode="marginless"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="pt-4">
              <SectionSubtitle
                text="1. Acceptance of the Terms"
                :level="3"
                mode="reduced"
              />
              <ContentParsed value="legal_terms_general_1" />
              <SectionSubtitle
                text="2. Unavailability and modification of the Site"
                :level="3"
              />
              <ContentParsed value="legal_terms_general_2" />
              <SectionSubtitle
                text="3. Intellectual property rights"
                :level="3"
              />
              <ContentParsed value="legal_terms_general_3" />
              <SectionSubtitle text="4. Severability" :level="3" />
              <ContentParsed value="legal_terms_general_4" />
              <SectionSubtitle text="5. Customer Service" :level="3" />
              <ContentParsed value="legal_terms_general_5" />
              <SectionSubtitle text="6. Liability" :level="3" />
              <SectionSubtitle
                text="a. Technical means for Users and/or Customers - Internet network"
                :level="4"
              />
              <ContentParsed value="legal_terms_general_6a" />
              <SectionSubtitle
                text="b. Third-party products and services"
                :level="4"
              />
              <ContentParsed value="legal_terms_general_6b" />
              <SectionSubtitle text="7. Personal data" :level="3" />
              <ContentParsed value="legal_terms_general_7" />
              <SectionSubtitle
                text="8. Jurisdiction, applicable law and mediation"
                :level="3"
              />
              <ContentParsed value="legal_terms_general_8" />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Legal - Terms - Additional Terms of Use -->
        <v-expansion-panel>
          <v-expansion-panel-header
            expand-icon="mdi-chevron-down mdi-36px"
            ripple
          >
            <SectionSubtitle
              text="Additional Terms of Use"
              :level="2"
              mode="marginless"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="pt-4">
              <SectionSubtitle
                text="1. Purpose of the Site"
                :level="3"
                mode="reduced"
              />
              <ContentParsed value="legal_terms_additional_terms_of_use_1" />
              <SectionSubtitle
                text="2. Quality of the information and service"
                :level="3"
              />
              <ContentParsed value="legal_terms_additional_terms_of_use_2" />
              <SectionSubtitle
                text="3. Guarantees – limited liability"
                :level="3"
              />
              <ContentParsed value="legal_terms_additional_terms_of_use_3" />
              <SectionSubtitle
                text="4. Notification in case of error or infringement"
                :level="3"
              />
              <ContentParsed value="legal_terms_additional_terms_of_use_4" />
              <SectionSubtitle
                text="5. Creation of hyperlink to the Site"
                :level="3"
              />
              <ContentParsed value="legal_terms_additional_terms_of_use_5" />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Legal - Terms - Additional Terms of Sale -->
        <v-expansion-panel>
          <v-expansion-panel-header
            expand-icon="mdi-chevron-down mdi-36px"
            ripple
          >
            <SectionSubtitle
              text="Additional Terms of Sale"
              :level="2"
              mode="marginless"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="pt-4">
              <SectionSubtitle
                text="1. Online shop"
                :level="3"
                mode="reduced"
              />
              <ContentParsed value="legal_terms_additional_terms_of_sale_1" />
              <SectionSubtitle text="2. Price" :level="3" />
              <ContentParsed value="legal_terms_additional_terms_of_sale_2" />
              <SectionSubtitle text="3. Online order" :level="3" />
              <ContentParsed value="legal_terms_additional_terms_of_sale_3" />
              <SectionSubtitle
                text="4. Order confirmation and payment"
                :level="3"
              />
              <ContentParsed value="legal_terms_additional_terms_of_sale_4" />
              <SectionSubtitle text="a. Payment" :level="4" />
              <ContentParsed value="legal_terms_additional_terms_of_sale_4a" />
              <SectionSubtitle text="b. Confirmation" :level="4" />
              <ContentParsed value="legal_terms_additional_terms_of_sale_4b" />
              <SectionSubtitle
                text="c. Authorized methods of payment"
                :level="4"
              />
              <ContentParsed value="legal_terms_additional_terms_of_sale_4c" />
              <SectionSubtitle text="5. Proof" :level="3" />
              <ContentParsed value="legal_terms_additional_terms_of_sale_5" />
              <SectionSubtitle text="6. Shipping and Deliveries" :level="3" />
              <SectionSubtitle text="a. Shipping" :level="4" />
              <ContentParsed value="legal_terms_additional_terms_of_sale_6a" />
              <SectionSubtitle text="b. Delivery" :level="4" />
              <ContentParsed value="legal_terms_additional_terms_of_sale_6b" />
              <SectionSubtitle text="c. Order verification" :level="4" />
              <ContentParsed value="legal_terms_additional_terms_of_sale_6c" />
              <SectionSubtitle text="d. Delivery error" :level="4" />
              <ContentParsed value="legal_terms_additional_terms_of_sale_6d" />
              <SectionSubtitle text="e. Returns and exchanges" :level="4" />
              <ContentParsed value="legal_terms_additional_terms_of_sale_6e" />
              <SectionSubtitle text="7. Guarantee of the Products" :level="3" />
              <ContentParsed value="legal_terms_additional_terms_of_sale_7" />
              <SectionSubtitle text="a. Conformity guarantee" :level="4" />
              <ContentParsed value="legal_terms_additional_terms_of_sale_7a" />
              <SectionSubtitle
                text="b. Invoking the legal guarantee"
                :level="4"
              />
              <ContentParsed value="legal_terms_additional_terms_of_sale_7b" />
              <SectionSubtitle text="8. Right to withdrawal" :level="3" />
              <ContentParsed value="legal_terms_additional_terms_of_sale_8" />
              <SectionSubtitle text="a. Model withdrawal form" :level="4" />
              <ContentParsed value="legal_terms_additional_terms_of_sale_8a" />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Legal - Terms - Annex - Rates and delivery -->
        <v-expansion-panel class="rounded-bl-lg rounded-br-lg">
          <v-expansion-panel-header
            expand-icon="mdi-chevron-down mdi-36px"
            ripple
          >
            <SectionSubtitle
              text="Annex - Rates and delivery"
              :level="2"
              mode="marginless"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="pt-4">
              <!-- <SectionSubtitle text="tbf" :level="3" mode="reduced" /> -->
              <!-- <ContentParsed value="tbf" /> -->
              <span>No Products are available so far in the online shop.</span>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section>

    <!-- Legal - Privacy -->
    <section>
      <SectionTitle text="Privacy policy" />
      <ContentParsed value="legal_privacy_intro_0" />
      <!-- Legal - Privacy - Important -->
      <SectionSubtitle text="Important" :level="3" />
      <ContentParsed value="legal_privacy_intro_1" />
      <!-- Legal - Privacy - Data controller -->
      <SectionSubtitle text="Data controller" :level="3" />
      <ContentParsed value="legal_privacy_intro_2" />
      <v-expansion-panels class="rounded-lg mt-6" hover multiple>
        <!-- Legal - Privacy - Data collection-->
        <v-expansion-panel class="rounded-tl-lg rounded-tr-lg">
          <v-expansion-panel-header
            expand-icon="mdi-chevron-down mdi-36px"
            ripple
          >
            <SectionSubtitle
              text="Data collection"
              :level="2"
              mode="marginless"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="pt-4">
              <SectionSubtitle
                text="1. Data that you send us directly"
                :level="3"
                mode="reduced"
              />
              <ContentParsed value="legal_privacy_collection_1" />
              <SectionSubtitle
                text="2. Data we collect in the course of our business relationship"
                :level="3"
              />
              <ContentParsed value="legal_privacy_collection_2" />
              <SectionSubtitle
                text="3. Information we collect automatically"
                :level="3"
              />
              <ContentParsed value="legal_privacy_collection_3" />
              <SectionSubtitle
                text="a. Data collected via cookies"
                :level="4"
              />
              <ContentParsed value="legal_privacy_collection_3a" />
              <SectionSubtitle
                text="b. Data collected by standard Internet technologies"
                :level="4"
              />
              <ContentParsed value="legal_privacy_collection_3b" />
              <SectionSubtitle
                text="c. Data provided by mobile devices"
                :level="4"
              />
              <ContentParsed value="legal_privacy_collection_3c" />
              <SectionSubtitle text="4. Data we assume" :level="3" />
              <ContentParsed value="legal_privacy_collection_4" />
              <SectionSubtitle text="5. Data relating to minors" :level="3" />
              <ContentParsed value="legal_privacy_collection_5" />
              <SectionSubtitle
                text="6. Data relating to a third party"
                :level="3"
              />
              <ContentParsed value="legal_privacy_collection_6" />
              <SectionSubtitle
                text="7. Exclusion of sensitive data"
                :level="3"
              />
              <ContentParsed value="legal_privacy_collection_7" />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Legal - Privacy - Purpose of your data -->
        <v-expansion-panel>
          <v-expansion-panel-header
            expand-icon="mdi-chevron-down mdi-36px"
            ripple
          >
            <SectionSubtitle
              text="Purpose of your data"
              :level="2"
              mode="marginless"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="pt-4">
              <ContentParsed value="legal_privacy_purpose_0" />
              <SectionSubtitle
                text="1. Operations necessary for the supply of Products or Services"
                :level="3"
              />
              <ContentParsed value="legal_privacy_purpose_1" />
              <SectionSubtitle
                text="2. Marketing and prospecting operations relating to the Company's publications, Products and Services"
                :level="3"
              />
              <ContentParsed value="legal_privacy_purpose_2" />
              <SectionSubtitle
                text="3. Operations related to commercial partnerships"
                :level="3"
              />
              <ContentParsed value="legal_privacy_purpose_3" />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Legal - Privacy - Storage period of data -->
        <v-expansion-panel>
          <v-expansion-panel-header
            expand-icon="mdi-chevron-down mdi-36px"
            ripple
          >
            <SectionSubtitle
              text="Storage period of data"
              :level="2"
              mode="marginless"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="pt-4">
              <ContentParsed value="legal_privacy_storage_0" />
              <SectionSubtitle
                text="1. Data relating to a prospect"
                :level="3"
              />
              <ContentParsed value="legal_privacy_storage_1" />
              <SectionSubtitle
                text="2. Data relating to an inactive Customer"
                :level="3"
              />
              <ContentParsed value="legal_privacy_storage_2" />
              <SectionSubtitle
                text="3. Data relating to an active Customer"
                :level="3"
              />
              <ContentParsed value="legal_privacy_storage_3" />
              <SectionSubtitle text="4. Assumed data" :level="3" />
              <ContentParsed value="legal_privacy_storage_4" />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Legal - Privacy - Accessibility of data -->
        <v-expansion-panel>
          <v-expansion-panel-header
            expand-icon="mdi-chevron-down mdi-36px"
            ripple
          >
            <SectionSubtitle
              text="Accessibility of data"
              :level="2"
              mode="marginless"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="pt-4">
              <SectionSubtitle
                text="1. Access to data within the Company"
                :level="3"
                mode="reduced"
              />
              <ContentParsed value="legal_privacy_accessibility_1" />
              <SectionSubtitle text="2. Transmission of data" :level="3" />
              <ContentParsed value="legal_privacy_accessibility_2" />
              <SectionSubtitle
                text="a. Subcontractors providing services on our behalf"
                :level="4"
              />
              <ContentParsed value="legal_privacy_accessibility_2a" />
              <SectionSubtitle
                text="b. Social networking platforms"
                :level="4"
              />
              <ContentParsed value="legal_privacy_accessibility_2b" />
              <SectionSubtitle text="c. Our business partners" :level="4" />
              <ContentParsed value="legal_privacy_accessibility_2c" />
              <SectionSubtitle
                text="d. Police authorities, judicial or administrative authorities"
                :level="4"
              />
              <ContentParsed value="legal_privacy_accessibility_2d" />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Legal - Privacy - Transfer outside of the EU -->
        <v-expansion-panel>
          <v-expansion-panel-header
            expand-icon="mdi-chevron-down mdi-36px"
            ripple
          >
            <SectionSubtitle
              text="Transfer outside of the EU"
              :level="2"
              mode="marginless"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="pt-4">
              <ContentParsed value="legal_privacy_transfer" />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Legal - Privacy - Data protection -->
        <v-expansion-panel>
          <v-expansion-panel-header
            expand-icon="mdi-chevron-down mdi-36px"
            ripple
          >
            <SectionSubtitle
              text="Data protection"
              :level="2"
              mode="marginless"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="pt-4">
              <ContentParsed value="legal_privacy_protection" />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Legal - Privacy - Your rights -->
        <v-expansion-panel class="rounded-bl-lg rounded-br-lg">
          <v-expansion-panel-header
            expand-icon="mdi-chevron-down mdi-36px"
            ripple
          >
            <SectionSubtitle text="Your rights" :level="2" mode="marginless" />
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <div class="pt-4">
              <SectionSubtitle
                text="1. Regarding access to your personal data"
                :level="3"
                mode="reduced"
              />
              <ContentParsed value="legal_privacy_rights_1" />
              <SectionSubtitle
                text="2. Regarding opposition to commercial solicitations"
                :level="3"
              />
              <ContentParsed value="legal_privacy_rights_2" />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section>
  </v-card>
</template>
<script>
import MixinView from "@/mixins/MixinView.js";

export default {
  name: "Legal",
  mixins: [MixinView]
};
</script>
