<template>
  <div>
    <!-- SM and up -->
    <v-row class="hidden-xs-only" align="center">
      <v-col cols="4">
        <ImageDialog :src="imgSrc" :title="title" :height="175" />
        <v-row v-if="chips" no-gutters class="mt-1" justify="center">
          <v-chip
            v-for="(chip, index) in chips"
            :key="index"
            :color="chip.color"
            class="mr-2 mt-2 rounded-tl-lg rounded-tr-0 rounded-bl-0 rounded-br-lg"
            text-color="white"
            label
            small
          >
            <span class="font-weight-bold">{{ $t(chip.name) }}</span>
          </v-chip>
        </v-row>
      </v-col>
      <v-col cols="8">
        <h3 v-if="!hiddenTitle" class="mb-2">{{ title }}</h3>
        <ContentParsed :value="textValue" />
        <!-- <p v-for="(text, index) in textValue" :key="index">{{ text }}</p> -->
        <v-row justify="center" no-gutters v-if="buttonTitle">
          <v-hover v-slot="{ hover }">
            <v-btn
              :to="buttonPath"
              :color="hover ? buttonColor + ' lighten-1' : buttonColor"
              class="white--text"
              large
              rounded
              :depressed="!hover"
            >
              <v-icon v-if="buttonIcon" left>{{ buttonIcon }}</v-icon>
              {{ buttonTitle }}
            </v-btn>
          </v-hover>
        </v-row>
      </v-col>
    </v-row>
    <!-- XS -->
    <v-row class="hidden-sm-and-up" align="center">
      <v-col>
        <h4 v-if="!hiddenTitle" class="mb-6  text-center">{{ title }}</h4>
        <v-row no-gutters justify="start">
          <ImageDialog :src="imgSrc" :title="title" :height="175" />
        </v-row>
        <v-row v-if="chips" no-gutters class="mt-1" justify="center">
          <v-chip
            v-for="(chip, index) in chips"
            :key="index"
            :color="chip.color"
            class="mr-2 mt-2 rounded-tl-lg rounded-tr-0 rounded-bl-0 rounded-br-lg"
            text-color="white"
            label
            small
          >
            <span class="font-weight-bold">{{ $t(chip.name) }}</span>
          </v-chip>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="hidden-sm-and-up" align="center">
      <v-col>
        <ContentParsed :value="textValue" />
        <!-- <p v-for="(text, index) in textValue" :key="index">{{ text }}</p> -->
        <v-row justify="center" no-gutters v-if="buttonTitle">
          <v-hover v-slot="{ hover }">
            <v-btn
              :to="buttonPath"
              :color="hover ? buttonColor + ' lighten-1' : buttonColor"
              class="white--text"
              large
              rounded
              :depressed="!hover"
            >
              <v-icon v-if="buttonIcon" left>{{ buttonIcon }}</v-icon>
              {{ buttonTitle }}
            </v-btn>
          </v-hover>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ImageDialog from "@/components/general/ImageDialog.vue";
import ContentParsed from "@/components/general/ContentParsed.vue";

export default {
  name: "GameCard",
  components: {
    ImageDialog,
    ContentParsed
  },
  data: () => ({
    isActive: false
  }),
  props: {
    imgSrc: String,
    title: String,
    textValue: String,
    buttonPath: String,
    buttonColor: String,
    buttonIcon: String,
    buttonTitle: String,
    chips: Array,
    hiddenTitle: Boolean
  }
};
</script>
