import ButtonHover from "@/components/general/ButtonHover.vue";
import ContentParsed from "@/components/general/ContentParsed.vue";
import ImageDialog from "@/components/general/ImageDialog.vue";
import SectionSubtitle from "@/components/general/SectionSubtitle.vue";
import SectionTitle from "@/components/general/SectionTitle.vue";

export default {

  components: {
    ButtonHover,
    ContentParsed,
    ImageDialog,
    SectionSubtitle,
    SectionTitle
  },
  methods: {
    srcImage(src) {
      return require("@/" + src);
    }
  }
};
