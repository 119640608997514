import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "@/locales",
    true,
    /([A-Z]+)+(_)+([a-z]+)+(-)+([a-z]+)\.json$/
  );

  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Z]+)+(_)+([a-z]+)+(-)+([a-z]+)\./);
    if (matched && matched.length > 1) {
      const locale = matched[1];

      // We have multiple files for every locale 
      // Therefore we need to check if a locale exists in the messages array so as to not override the previous files' datas
      if (!(locale in messages)) {
        // Locale doesn't exist -> create it
        messages[locale] = locales(key);
      }
      else {
        // Locale exists -> append new entry
        Object.assign(messages[locale], locales(key));
      }
    }
  });

  return messages;
}

export default new VueI18n({
  locale:  "FR", //process.env.VUE_APP_I18N_LOCALE || ,
  fallbackLocale: "FR", //process.env.VUE_APP_I18N_FALLBACK_LOCALE || 
  messages: loadLocaleMessages()
});
