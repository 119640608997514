import Vue from "vue";
import Vuex from "vuex";
// import i18n from "@/i18n";
// import VuexPersist from "vuex-persist";
// import Cookies from 'js-cookie'
import nav from "@/assets/data/nav.json";

Vue.use(Vuex);

//Cookie: Persitent & Session
//Local Storage
//Cache

/*
	state (data)
	mutations (sync methods)
	actions (async methods)
	getters (computed)
 */

/*
	this.$store.state...
	store.getters...
	store.commit(name,payload)
 */

const navItems = (function() {
  let items = [];
  let navInfo = nav.navInfo;

  navInfo.forEach(item => {
    items.push({
      to: item.path,
      icon: item.icon,
      fieldName: item.fieldName,
      componentName: item.componentName,
      badge: true
    });
  });

  return items;
})();

const lastUsedModule = {
  namespaced: true,
  state: {
    currentLanguage: "FR"
  },
  mutations: {},
  actions: {},
  getters: {}
};

const navigationModule = {
  namespaced: true,
  state: {
    isDrawer: false,
    navItems
  },
  mutations: {
    setDrawer(state, payload) {
      // this.$store.commit('setDrawer',<bool>)
      state.isDrawer = payload;
    },
    openDrawer(state) {
      // this.$store.commit('openDrawer')
      state.isDrawer = true;
    },
    closeDrawer(state) {
      // this.$store.commit('closeDrawer')
      state.isDrawer = false;
    },
    visitNav(state, navComponentName) {
      // this.$store.commit('visitNav',<navFieldName>)
      state.navItems.forEach(function(item) {
        if (navComponentName === item.componentName) {
          item.badge = false;
        }
      });
    }
  },
  actions: {},
  getters: {
    nbVisitRemaining(state) {
      // this.$store.getters.nbVisitRemaining
      let nb = 0;
      state.navItems.forEach(function(item) {
        if (item.badge) {
          nb++;
        }
      });
      return nb;
    },
    isVisitRemaining(state, getters) {
      // this.$store.getters.isVisitRemaining
      return getters.nbVisitRemaining !== 0;
    },
  }
};

// const vuexPersist = new VuexPersist({
//   key: "critical-hut",
//   storage: window.localStorage
// });

// const vuexCookie = new VuexPersist({
//   restoreState: (key) => Cookies.getJSON(key),
//   saveState: (key, state) => Cookies.set(key, state),
//   modules: ['nav']
// })

export default new Vuex.Store({
  modules: {
    nav: navigationModule,
    lastUsed: lastUsedModule
  }
  // plugins: [vuexCookie.plugin, vuexPersist.plugin]
  // plugins: [vuexPersist.plugin]
});
