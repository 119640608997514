<template>
  <v-app-bar height="70" elevate-on-scroll dark app>
    <!-- App Bar Icon -->
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon @click="openDrawer" v-bind="attrs" v-on="on">
          <v-badge
            :value="isVisitRemaining"
            offset-x="-1"
            offset-y="-1"
            color="red"
            dot
          >
            <v-icon>mdi-menu mdi-36px</v-icon>
          </v-badge>
        </v-app-bar-nav-icon>
      </template>
      <span>Open menu</span>
    </v-tooltip>
    <v-spacer></v-spacer>
    <!-- App Bar Logo -->
    <router-link to="/" aria-label="Home">
      <v-img
        src="@/assets/img_logos/logo.svg"
        max-height="44"
        min-height="44"
        min-width="170"
        max-width="170"
        @click="scrollToTop"
        contain
      ></v-img>
    </router-link>
    <v-spacer></v-spacer>
    <!-- App Bar Option -->
    <v-menu left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" depressed>
          <v-icon left>mdi-web mdi-24px</v-icon>
          <span class="text-h6">{{ $i18n.locale }}</span>
        </v-btn>
      </template>

      <v-list-item-group v-model="$i18n.locale" color="primary" mandatory>
        <v-list>
          <v-list-item v-for="language in languages" :key="language.key" :value="language.key" @click="scrollToTop">
            <v-list-item-title>{{ language.key }} - {{ language.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list-item-group>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { getAllLocales } from "@/helpers/localeHelper.js";

export default {
  name: "AppBar",
  computed: {
    ...mapGetters("nav", ["isVisitRemaining"]),
    languages: function() {
      return getAllLocales();
    }
  },
  methods: {
    ...mapMutations("nav", ["openDrawer"]),
    scrollToTop() {
      if (window.scrollY != 0) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    }
  }
};
</script>
