<template>
  <v-hover v-slot="{ hover }">
    <v-list-item rounded :href="link" target="_blank" link :class="listItemC(hover)">
      <v-list-item-avatar size="50">
        <v-img :src="srcImage(imageSrc)"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-h6">{{ title }}</v-list-item-title>
        <v-list-item-subtitle>
          <v-icon small class="mr-1" :class="whiteTextHC(hover)">mdi-calendar</v-icon>
          <span :class="whiteTextHC(hover)">{{ date }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon aria-label="external link" :class="whiteTextHC(hover)"><v-icon>mdi-open-in-new</v-icon></v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-hover>
</template>

<script>
import MixinView from "@/mixins/MixinView.js";

export default {
  name: "EventItem",
  mixins: [MixinView],
  props: {
    title: String,
    date: String,
    link: String,
    imageSrc: String,
    hide: Boolean,
  },
  methods: {
    listItemC(hover) {
      let text = "d-flex transition-fast-in-fast-out mb-2";
      text += hover ? " ml-2 primary white--text" : "";
      return text;
    },
    whiteTextHC(hover) {
      return hover ? "white--text" : "";
    }
  }
};
</script>