<template>
  <v-row justify="center" no-gutters>
    <v-hover v-slot="{ hover }">
      <v-lazy v-model="isActive" :options="{ threshold: 1 }" height="44" transition="slide-x-transition">
        <div>
          <v-btn
            v-if="path"
            :to="path"
            :color="hover ? color + ' lighten-1' : color"
            :depressed="!hover"
            class="white--text"
            :disabled="disabled"
            large
            rounded
          >
            <v-icon v-if="icon" left>{{ icon }}</v-icon>
            <span>{{ title }}</span>
          </v-btn>
          <v-btn
            v-if="link"
            :href="link"
            target="_blank"
            :color="hover ? color + ' lighten-1' : color"
            :depressed="!hover"
            class="white--text"
            :disabled="disabled"
            large
            rounded
          >
            <v-icon v-if="icon" left>{{ icon }}</v-icon>
            <span>{{ title }}</span>
            <v-icon :class="hover ? 'pl-1' : ''" right>mdi-open-in-new</v-icon>
          </v-btn>
        </div>
      </v-lazy>
    </v-hover>
  </v-row>
</template>
<script>
export default {
  name: "ButtonHover",
  data: () => ({
    isActive: false
  }),
  props: {
    path: String,
    link: String,
    color: String,
    icon: String,
    title: String,
    disabled: Boolean
  }
};
</script>
