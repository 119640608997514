<template>
  <v-footer app padless dark>
    <v-row justify="center" no-gutters>
      <v-col class="ma-1 text-center text-caption" cols="12">
        © {{ yearRange }} {{ $t("footer_rights") }}
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",
  computed: {
    yearRange() {
      const startYear = 2020,
        currentYear = new Date().getFullYear();
      if (currentYear === startYear) {
        return "2020";
      } else {
        return startYear + "-" + currentYear;
      }
    }
  }
};
</script>
