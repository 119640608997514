<template>
	<v-card class="mx-auto px-4 pt-3 pb-16 mb-16" max-width="800" color="transparent" flat tile>
		<section>
			<SectionTitle text="Changelog" marginlesstop />
			<v-alert border="left" icon="mdi-alert" type="warning" color="primary" dismissible dense>{{$t('info_onlyenglish')}}</v-alert>
			<v-switch v-model="switchModel" label="Show minor updates"></v-switch>
			<br />
			<v-timeline dense>
				<!-- <v-scroll-x-transition group> -->
					<TimelineItem v-for="(item, index) in appChangelog" :item="item" :key="index" :switchModel="switchModel"></TimelineItem>
				<!-- </v-scroll-x-transition> -->
			</v-timeline>
		</section>
	</v-card>
</template>

<script>
import TimelineItem from "@/components/specific/TimelineItem.vue";
import MixinView from "@/mixins/MixinView.js";
import changelog from "@/assets/data/changelog.json"

export default {
	name: "Changelog",
	mixins: [MixinView],
	data() {
		return {
			switchModel: true
		}
	},
	components: {
		TimelineItem
	},
	beforeMount() {
		this.appChangelog=changelog["appChangelog"];
	}
};
</script>



