<template>
  <div>
    <v-card class="mx-auto px-4 pt-3 pb-16 mb-16" max-width="800" color="transparent" flat tile>
      <!-- Home - Welcome -->
      <section>
        <SectionTitle :text="$t('home_welcome_title')" marginlesstop/>
        <ContentParsed value="home_welcome_intro" />
        <ButtonHover :title="$t('home_welcome_button')" path="/games" color="primary" icon="mdi-cards" />
      </section>
    <!-- </v-card> -->

   <!--  <v-card class="my-6" tile flat color="#DCBF96">
      <v-banner>
      </v-banner>
    </v-card> -->

    <!-- <v-card class="mx-auto px-4" max-width="800" color="transparent" flat tile> -->
      <!-- Home - Events -->
      <EventsSection/>

      <!-- Home - Hut -->
      <section>
        <SectionTitle :text="$t('home_hut_title')" />
        <ContentParsed value="home_hut_intro" />
        <ButtonHover :title="$t('home_hut_button')" path="/team" color="hut2" icon="mdi-magnify" />
      </section>

      <!-- Home - Community -->
      <section>
        <SectionTitle :text="$t('home_community_title')" />
        <ContentParsed value="home_community_1" />
        <v-row class="mx-8 my-4">
          <ImageDialog src="assets/img_photos/Family Playing.webp" :height="400"/>
        </v-row>
        <ContentParsed value="home_community_2" />
        <ButtonHover :title="$t('home_community_button')" :link="linkCHInstagram" color="hut3" icon="mdi-fire" />
      </section>
    </v-card>
  </div>
</template>
<script>
  import MixinView from "@/mixins/MixinView.js";
  import EventsSection from "@/components/specific/EventsSection.vue";

  import database from "@/assets/data/database.json";

  export default {
    name: "Home",
    mixins: [MixinView],
    props: {
      isFrom404: Boolean
    },
    beforeMount() {
      this.linkCHInstagram=database["linkCHInstagram"];
    },
    components: {
      EventsSection
    }
  };
</script>
