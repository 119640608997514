import languages from "@/locales/languages.json";

function getAllLocales() {
  let languageKeys = Object.keys(languages); // keys are DE, EN, FR, ...
  let languageValues = Object.values(languages); // values are {"EN": "English","DE": "Deutsch","FR": "Français",...}

  // Compose the list elements
  let result = [];
  for (let i = 0; i < languageKeys.length; i++) {
    result.push({ key: languageKeys[i], name: languageValues[i] });
  }

  // Array of { key, name } where key is EN etc, and name is English etc
  return result;
}

function getInactiveLocales(activeLocale) {
  let allLocales = getAllLocales();
  let inactiveLocales = allLocales.filter(function(l) {
    return l.key !== activeLocale;
  });

  return inactiveLocales;
}

export {
  getAllLocales,
  getInactiveLocales
};