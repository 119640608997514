<template>
  <v-list-item-group>
    <v-list-item
      v-for="item in navItems"
      :key="item.to"
      :to="item.to"
      v-slot="{ active }"
      active-class="primary--text"
    >
      <v-list-item-icon v-if="item.fieldName == 'nav_the7seas'">
        <v-icon right :color="active ? 'primary' : ''">{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-icon v-else>
        <v-icon :color="active ? 'primary' : ''">{{ item.icon }} mdi-36px</v-icon>
      </v-list-item-icon>
      <v-list-item-title v-if="item.fieldName == 'nav_the7seas'">
        <span class="text-h6 font-weight-medium">
          <v-badge :value="item.badge" offset-x="-12" offset-y="14" color="red" dot>
            {{ $t(item.fieldName) }}
          </v-badge>
        </span>
      </v-list-item-title>
      <v-list-item-title v-else>
        <span class="text-h5 font-weight-medium">
          <v-badge :value="item.badge" offset-x="-12" offset-y="18" color="red" dot>
            {{ $t(item.fieldName) }}
          </v-badge>
        </span>
      </v-list-item-title>
    </v-list-item>
  </v-list-item-group>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "NavigationDrawerList",
  data: () => ({
    activeRouteTo: "/"
  }),
  computed: {
    ...mapState("nav", ["navItems"])
  },
  mounted() {
    this.activeRouteTo = this.$router.currentRoute.path;
  },
  methods: {
    isActiveRoute(itemTo) {
      return this.activeRouteTo === itemTo;
    }
  }
};
</script>
